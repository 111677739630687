/* index.css */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;  /* Example font */
}

body {
  background-color: #f4f4f4;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}