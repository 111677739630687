.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

h1 {
  margin-top: 20px;
  font-size: 2.5em;
  color: #e50914; /* Netflix Red */
}

.input-container {
  text-align: center;
  width: 100%;
  margin: 20px 0;
}

.subscription-container {
  text-align: center;
  width: 100%;
  margin: 40px 0;
}

.subscription-input {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
}

.chart-container {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

a.red-link {
  color: red; /* Default state */
}
a.red-link:hover {
  color: darkred; /* Darker when hovered */
}
a.red-link:active {
  color: maroon; /* Even darker when clicked */
}
